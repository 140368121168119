<template>
  <div>
    <qrcode class="qr-code" :value="getPath()" :options="{ width: 100 }"></qrcode>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Vue from "vue";
Vue.component(VueQrcode.name, VueQrcode);
export default {
  name: "QRCodeComponent",
  methods: {
    getPath() {
      return window.location.href;
    },
  },
};
</script>
