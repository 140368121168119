<template>
  <section class="certificates__page" v-if="loadPage">
    <div class="certificates" v-if="load">
      <b-container>
        <div class="d-flex align-items-center certificates__logo">
          <div>
            <img width="60px" src="@/assets/imgs/logo-certificate.png" />
          </div>
          <p class="mx-3 mb-0 font-weight-bold">Solutionplus</p>
        </div>
        <div class="d-flex">
          <div class="d-flex flex-column">
            <p class="certificates__heading font-weight-bold">Certificate</p>
            <p class="certificates__sub-heading font-weight-bold">
              OF
              <span class="text-capitalize">
                {{ certificate.type }}
              </span>
            </p>
          </div>
        </div>

        <p class="certificates__name font-weight-bold mt-n1">
          <span class="text-dark"> This is to certify that </span>
          <br />
          <span>
            {{ certificate.name }}
          </span>
        </p>
        <div class="d-flex">
          <div class="certificates__description">
            <p>
              Has successfully completed the
              <span>
                {{ certificate.training_department?.name }}
              </span>
              internship
              <br />
              held on the dates from
              <span class="font-weight-bold">
                {{ formatDate(certificate.start_date.date) }}
              </span>

              to
              <span class="font-weight-bold">
                {{ formatDate(certificate.end_date.date) }}.
                <span>
               
                </span>
              </span>
           
            </p>
            <div>
              <p class="font-weight-bold" v-if="certificate.type == 'completion'">
                We appreciate your dedication and effort in completing this program.
              </p>
              <p class="font-weight-bold" v-else-if="certificate.type == 'achievement'">
                We appreciate your hard work and determination in achieving this
                milestone.
              </p>
              <p class="font-weight-bold" v-else-if="certificate.type == 'excellence'">
                We appreciate your outstanding performance and commitment to excellence.
              </p>
            </div>
          </div>
          <div>
            <!-- <img class="mx-4" width="90px" src="@/assets/imgs/qr-code.png" /> -->
          <QRCodeComponent />
          </div>
        </div>
        <div class="mt-4 certificates__footer d-flex align-content-center">
          <div class="text-center">
            <p class="mb-0 font-weight-bold">
              {{ formatDate(certificate.release_date.date) }}
            </p>
            <hr />
            <p class="mb-0 font-weight-bold">DATE</p>
          </div>
          <div class="mr-custom-logo text-center">
            <img width="120px" src="@/assets/imgs/logo-footer.png" />
          </div>
          <div class="text-center mr-custom-sign">
            <img width="100px" src="@/assets/imgs/sign.png" />
            <hr />
            <p class="mb-0 font-weight-bold">SIGNATURE</p>
          </div>
        </div>
      </b-container>
    </div>
    <div class="certificates" v-else>
      <b-container>
        <div class="d-flex align-items-center certificates__logo mb-5 pb-5">
          <div>
            <img width="60px" src="@/assets/imgs/logo-certificate.png" />
          </div>
          <p class="mx-3 mb-0 font-weight-bold">Solutionplus</p>
        </div>
        <h2 class="text-danger mt-5 pt-5">No Results Found...</h2>
      </b-container>
    </div>
  </section>
</template>
<script>
import cookies from "js-cookie";
import QRCodeComponent from "../../../components/QrCode/index";
import { IndexData } from "@/helpers/apiMethods";

export default {
  components: {
    QRCodeComponent,
  },
  data() {
    return {
      loadPage: false,
      load: false,
      certificate: {
        id: 2,
        serial_number: "9011121942",
        name: "test name",
        type: "achievement",
        start_date: {
          date: "2024-07-12",
          timezone_type: 3,
          timezone: "UTC",
        },
        end_date: {
          date: "2024-07-12",
          timezone_type: 3,
          timezone: "UTC",
        },
        release_date: {
          date: "2024-07-23",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
    };
  },
  created() {
    if (cookies.get("language") != "en") {
      cookies.set("language", "en");
      window.location.reload();
    } else {
      this.fetchSlugData();
    }
  },
  methods: {
    async fetchSlugData() {
      try {
        const {
          data: { certificate },
        } = await IndexData({ reqName: `certificates/${this.$route.params.slug}` });
        this.certificate = certificate;
        this.loadPage = true;
        this.load = true;

      } catch (err) {
        this.loadPage = true;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: "numeric", month: "long", year: "numeric" };
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    },
    returnHumanDate(date) {
      if (date) {
        let splitDate = date.split(" ");
        return splitDate[0];
      }
      return date;
    },
    returnDate(date) {
      if (date) {
        let splitDate = date.split(" ");
        return splitDate[0];
      }
      return date;
    },
  },
};
</script>

<style>
.navbar,
.footer {
  display: none !important;
}
main {
  overflow-y: hidden;
}

@media (min-width: 1700px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}
</style>
